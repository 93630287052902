














































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import InfoDialogue from "@/vue/components/InfoDialogue.vue";
import QuestionnairePage from "@/vue/components/AssessmentPages/QuestionnairePage.vue";
import store from "@/store/store";
import UploadPage from "@/vue/components/AssessmentPages/UploadPage.vue";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { InfoType } from "@/model/Enums";
import { ISaveResponse } from "@/model/ISaveResponse";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ConfirmDialogue,
        InfoDialogue,
        QuestionnairePage,
        UploadPage
    }
})
export default class Assessment extends Vue {

    async mounted() {
        this.load();
    }

    //
    // -- properties
    //

    assessmentID = "";
    private readonly assessment = new AssessmentFormVM();
    private section = new SectionVM();
    private sectionNumber = 1;

    get assessmentTitleText() {
        if (this.assessment.isSubmitted) {
            return "(Submitted)";
        } else return "(Incomplete)";
    }

    get submitEnabled() {
        var retVal = false;
        var totalQuestions = 0;
        var totalAnswers = 0;

        this.assessment.sections.forEach(s => {
            totalQuestions += s.questionCount;
            totalAnswers += s.answeredQuestionCount;
        })

        var totalFRARequired = 0;
        var totalFRAUploaded = 0;

        totalFRARequired = this.assessment.propertyFras.length;

        this.assessment.propertyFras.forEach(p => {
            if (p.friDocument.name != "") {
                totalFRAUploaded += 1;
            }
        })

        if (totalAnswers != totalQuestions) {
            retVal = true;
        }

        if (totalFRARequired != totalFRAUploaded) {
            retVal = true;
        }

        return retVal;
    }

    get questionnaireBlobColour() {
        let retVal = "green";

        var totalQuestions = 0;
        var totalAnswers = 0;

        this.assessment.sections.forEach(s => {
            totalQuestions += s.questionCount;
            totalAnswers += s.answeredQuestionCount;
        });

        if (totalQuestions != totalAnswers) {
            retVal = "red"
        }

        return retVal;
    }

    get uploadsBlobColour() {
        let retVal = "green";

        // FRAs
        var requiredFRAs = this.assessment.propertyFras.length;
        var uploadedFRAs = 0;

        this.assessment.propertyFras.forEach(pd => {
            if (pd.friDocument.name != "") {
                uploadedFRAs += 1;
            }
        })

        if (requiredFRAs != uploadedFRAs) {
            retVal = "red";
        }

        // regular uploads

        this.assessment.sections.forEach(s => {
            s.questions.forEach(q => {
                q.answerUploads.forEach(au => {
                    if (!au.answerUpload.completed) {
                        retVal = "red";
                    }
                })
            })
        })

        return retVal;
    }

    //
    // -- methods
    //

    private async load() {
        const assessmentData = await apiClient.get(`api/assessment/Load?id=${store.state.signedInUser?.landlordID}`);
        this.assessment.update(assessmentData);
        this.resetSection();
    }

    setSection(section: SectionVM) {
        this.assessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.assessment.sections.forEach(s => {
            if (s.assessmentSection.id == section.assessmentSection.id) {
                s.isSelected = true;
                this.section = section;
            }
        });

        this.load();
    }

    sectionForward() {
        this.sectionNumber += 1;

        this.assessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.assessment.sections.forEach(s => {
            if (s.sectionNumber == this.sectionNumber) {
                s.isSelected = true;
                this.section = s;
            }
        });

        this.load();
        window.scrollTo(0, 0);
    }

    sectionBack() {
        this.sectionNumber -= 1;

        this.assessment.sections.forEach(s => {
            if (s.isSelected) {
                s.isSelected = false;
            }
        });

        this.assessment.sections.forEach(s => {
            if (s.sectionNumber == this.sectionNumber) {
                s.isSelected = true;
                this.section = s;
            }
        });

        this.load();
        window.scrollTo(0, 0);
    }

    resetSection() {
        var selectedSectionCount = 0;

        this.assessment.sections.forEach(s => {
            if (s.assessmentSection.id == this.section.assessmentSection.id && this.section.isSelected) {
                s.isSelected = true;
                this.section = s;
                this.sectionNumber = s.sectionNumber;
                selectedSectionCount++;
            }
        });

        // set first section on initial load
        if (selectedSectionCount == 0) {
            this.assessment.sections[0].isSelected = true;
            this.section = this.assessment.sections[0];
            this.sectionNumber = 1;
        }
    }

    private confirmSubmitAssessment() {
        const dialog: ConfirmDialogue = this.$refs.submitConfirmDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async submitAssessment() {
        const response: ISaveResponse = await apiClient.post("/api/assessment/submitAssessment", this.assessment.assessment);
        if (response.isSuccess) {
            toastr.success("Submitted");
            this.load();
        }
        
        const dialog: ConfirmDialogue = this.$refs.submitConfirmDialogue as ConfirmDialogue;
        dialog.hide();

        const infoDialog: InfoDialogue = this.$refs.infoDialogue as InfoDialogue;
        infoDialog.open(InfoType.OSAQCompleted);
    }
}
